import config from 'Data';
import React from 'react';
import { Container, Row, Col, Jumbotron } from 'reactstrap';
import { StaticQuery, graphql } from 'gatsby';
import { FullContainer, Layout, Head, CardPost } from 'Common';

const Blog = () => (
  <StaticQuery
    query={graphql`
      query BlogQuery {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { glob: "**/src/pages/blog/**/*.md" } }
          sort: { order: DESC, fields: [frontmatter___date] }
          limit: 20
        ) {
          edges {
            node {
              excerpt(pruneLength: 80)
              id
              timeToRead
              frontmatter {
                title
                author
                author_avatar {
                  childImageSharp {
                    fluid(maxWidth: 700, maxHeight: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                date(formatString: "MMM DD, YYYY")
                path
                thumbnail {
                  childImageSharp {
                    fluid(maxWidth: 700, maxHeight: 400) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => (
      <Layout>
        <Head type="Organization" location="/blog">
          Blog | {config.legalName}
        </Head>
        <Jumbotron fluid className="bg-dark mb-0">
          <Container>
            <h1 className="display-4 pt-5">Articles</h1>
            <p className="lead">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry’s standard dummy text
              ever since the 1500s.
            </p>
          </Container>
        </Jumbotron>
        <FullContainer>
          <Container className="py-5">
            <Row>
              {data.allMarkdownRemark.edges.map(post => (
                <Col key={post.node.id} xs="12" sm="6" md="4" className="mb-3">
                  <CardPost {...post} />
                </Col>
              ))}
            </Row>
          </Container>
        </FullContainer>
      </Layout>
    )}
  />
);

export default Blog;
